<template>
    <v-row class="process-img-listing justify-center" no-gutters>
        <v-col class="pa-2" cols="12" md="3">
            <v-card-title class="green lighten-1 mb-3 rounded-lg pa-1 white--text text-uppercase"><div class="mx-auto"> {{processImgListing[0].title}} </div></v-card-title>
            <v-row no-gutters>
                <v-col v-for="(subItem, index) in processImgListing[0].content" :key="index" cols="6" class="px-2">
                    <v-card class="green lighten-3 rounded-lg">
                        <v-card-title class="white--text green lighten-2 mb-2 pa-1 rounded-lg">
                            <div class="mx-auto">{{subItem.subTitle}}</div>
                        </v-card-title>
                        <v-card-text>
                            <v-col class="text-center my-2 white rounded-lg text-truncate elevation-2" v-for="textItem in subItem.subContent" :key="textItem">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">{{textItem}}</span>
                                    </template>
                                    <span>{{textItem}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="pa-2" cols="12" md="5">
            <v-card-title class="light-blue darken-3 mb-3 rounded-lg pa-1 white--text text-uppercase"><div class="mx-auto"> {{processImgListing[1].title}} </div></v-card-title>
            <v-row no-gutters>
                <v-col v-for="(subItem, index) in processImgListing[1].content" :key="index" cols="4" class="px-2">
                    <v-card class="light-blue darken-1 rounded-lg">
                        <v-card-title class="white--text light-blue darken-2 mb-2 pa-1 rounded-lg">
                            <div class="mx-auto">{{subItem.subTitle}}</div>
                        </v-card-title>
                        <v-card-text>
                            <v-col class="text-center my-2 white rounded-lg text-truncate elevation-2" v-for="textItem in subItem.subContent" :key="textItem">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">{{textItem}}</span>
                                    </template>
                                    <span>{{textItem}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
        <v-col class="pa-2" cols="12" md="3">
            <v-card-title style="opacity:0" class="mb-3 pa-1"><div class="mx-auto"> {{processImgListing[2].title}} </div></v-card-title>
            <v-row no-gutters>
                <v-col v-for="(subItem, index) in processImgListing[2].content" :key="index" cols="6" class="px-2">
                    <v-card class="rounded-lg" :class="index == 0?'grey lighten-1':'deep-purple lighten-3'">
                        <v-card-title :class="index == 0?'grey darken-1':'deep-purple lighten-1'" class="white--text lighten-2 mb-2 pa-1 rounded-lg">
                            <div class="mx-auto">{{subItem.subTitle}}</div>
                        </v-card-title>
                        <v-card-text>
                            <v-col class="text-center my-2 white rounded-lg text-truncate elevation-2" v-for="textItem in subItem.subContent" :key="textItem">
                               <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">{{textItem}}</span>
                                    </template>
                                    <span>{{textItem}}</span>
                                </v-tooltip>
                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
      </v-row>
</template>

<script>
export default {
    data: () =>({
        processImgListing: [
            {
                title: "initial screening",
                content: [
                    {
                        subTitle: "Zeroth Phase",
                        subContent: ["Architecture","System Design","Implementation","Reuse"]
                    },
                    {
                        subTitle: "First Phase",
                        subContent: ["Structure Analysis","Design constraints","Style Implementation","Priority Behaviour","Refinement"]
                    }
                ]
            },
            {
                title: "Supreme Layer",
                content: [
                    {
                        subTitle: "A Layer",
                        subContent: ["Quality of services","Functional Goal","Non functional goal","Objectives"]
                    },
                    {
                        subTitle: "B Layer",
                        subContent: ["Dependency Analysis","Distributed Object Design","Safety Properties","Model validation","Approach Description"]
                    },
                    {
                        subTitle: "C Layer",
                        subContent: ["Correctness and Completeness","Test selection stage","Automation Approach"]
                    }
                ]
            },
            {
                title: "No title",
                content: [
                    {
                        subTitle: "Acceptance",
                        subContent: ["Computation Configuration and coordination layer","Tool Support","Accessing Shared Data","Queueing System","Translating and buffering"]
                    },
                    {
                        subTitle: "Supplementry",
                        subContent: ["Practical Methodology","Smart Compiler Architecture","Sequence Pipeline and Distributor","Operational and Maintenence"]
                    }
                ]
            },
        ]
    })
}
</script>

<style lang="scss">
    .process-img-listing {
        line-height: 1.6;
        .v-card__title{
            font-size: 14px;
            font-weight: 600;
        }
        .v-card__text{
            font-size: 10px;
            text-transform: capitalize;
            font-weight: 600;
            padding: 8px;
            line-height: 1.6;
            .col{
                padding: 20px 10px;
            }
        }
    }
</style>