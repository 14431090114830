<template>
    <div class="home">
        <v-sheet class="news-redesign">
            <v-container class="new-container">
                <v-card-text>
                    <v-row justify="center">
                        <v-col cols="12" sm="10" md="8">
                            <ul  ref="newContent" class="startScroll" style="list-style:none"><li v-for="(li,index) in newsSection" :key="index"><b v-if="li.isNew" class="new--text">New!&nbsp;</b>{{li.msg}}</li></ul>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-container>
        </v-sheet>
        <v-sheet class="banner grey lighten-5">
            <div class="circle one hidden-sm-and-down"></div>
            <div class="circle two hidden-sm-and-down"></div>
            <v-container>
                <v-row justify="center" align="center">
                    <v-col cols="12" sm="6">
                        <v-card-text class="text-center">The next level of allegiance. <b>Chance to cogitate and grow together.</b></v-card-text>
                        <v-card-title class="pt-0"><div class="col-sm-12 text-center pa-0">Authcode Limited</div></v-card-title>
                        <v-card-text class="text-center">We are expertise in delivering effortless solution using continious innovation. We measure, evaluate and action as per the customer needs. As a value added service we provides QA & software testing services to fulfill all your quality and testing needs with strong quality standard. we are expertise in, performing all types of product development and testing in various domains includes Telecom, Banking and Financial Services, Manufacturing and Logistics, Healthcare and Insurance, E-commerce.</v-card-text>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-sheet class="adv-section py-50">
            <v-container>
                <v-row no-gutters>
                    <v-col cols="6" class="hidden-sm-and-down">
                        <v-img src="../assets/logo_animated.gif"></v-img>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-container>
                            <v-card-text class="pl-0">Its simple - Go through our special wizard, use our expereince resources pool and drive yourself.</v-card-text>
                            <v-card class="my-3 ml-0">
                                <v-card-title><v-img width="50" src="../assets/1.png"></v-img></v-card-title>
                                <v-card-text class="py-5">Let's make it happen</v-card-text>
                            </v-card>
                            <v-card class="my-3 ml-0">
                                <v-card-title><v-img src="../assets/2.png"></v-img></v-card-title>
                                <v-card-text class="py-5">Join our hands</v-card-text>
                            </v-card>
                            <v-card class="my-3 ml-0">
                                <v-card-title><v-img src="../assets/3.png"></v-img></v-card-title>
                                <v-card-text class="py-5">Your vision is our vision</v-card-text>
                            </v-card>
                            <v-card class="my-3 ml-0">
                                <v-card-title><v-img src="../assets/4.png"></v-img></v-card-title>
                                <v-card-text class="py-5">Embrace risks</v-card-text>
                            </v-card>
                            <v-card class="my-3 ml-0">
                                <v-card-title><v-img src="../assets/5.png"></v-img></v-card-title>
                                <v-card-text class="py-5">Transform ideas into reality</v-card-text>
                            </v-card>
                        </v-container>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-sheet class="py-50" dark>
            <v-row no-gutters align="center">
                <v-col sm="1" class="visible-sm-and-up"></v-col>
                <v-col cols="12" sm="4" class="visible-sm-and-up">
                    <v-container>
                        <v-img src="../assets/scope_image.png"></v-img>
                    </v-container>
                </v-col>
                <v-col sm="1" class="visible-sm-and-up"></v-col>
                <v-col cols="12" sm="6">
                    <v-container>
                        <v-card elevation="0">
                            <v-card-text>
                                We believe in loading our extreme techniques to execrsise the requisite demands. Main focus is to launch influencing user control portal to facilitate continous support, faith in persistence melioration.<br />
                                We process your ideas into powerful plan, construct and restructure your thoughts to a product.
                                <ul>
                                    <li>Detailed scope of an idea.</li>
                                    <li>Research and perform literature Review</li>
                                    <li>Secure Intellectual property</li>
                                    <li>Analyse and decide the Target Audience and ROI</li>
                                    <li>Powerful Risk analysis and POC</li>
                                    <li>Prototype model</li>
                                    <li>Master Business plan and Design layout</li>
                                    <li>Product development</li>
                                    <li>Quality control</li>
                                    <li>Delivery and support</li>
                                </ul>
                            </v-card-text>
                        </v-card>
                    </v-container>
                </v-col>
            </v-row>
        </v-sheet>
        <v-container>
            <v-img src="../assets/flowImage.png"></v-img>
        </v-container>
        <ContactParallaxComponent />
        <ProcessImageComponent class="process-img" />
        <!-- <NewsComponent /> -->
    </div>
</template>

<script>
import ProcessImageComponent from '@/components/ProcessImage.vue'
import ContactParallaxComponent from '@/components/ContactParallax.vue'
// import NewsComponent from '@/components/NewsComponent.vue'
export default {
    name: "Home",
    components: {
        ProcessImageComponent,
        ContactParallaxComponent,
        // NewsComponent
    },
    data:()=>{
        return {
            newsSection: [
                {isNew: true, msg: 'Cogitate and grow together!'},
                {isNew: false, msg: 'Having better software developed for your needs is not so much hard as it is different from what you are used to.'},
                {isNew: false, msg: 'we look at a progressively beneficial set of approaches to get you from where you are now to organizational agility.'}
            ]
        }
    },
    mounted(){
        this.toggleNewsWindow();
    },
    methods: {
        toggleNewsWindow: function(){
            const height = (this.$refs.newContent.scrollHeight * 18) / 100;
            console.log(height);
            document.documentElement.style.setProperty('--scroll-time-2', height+'s');
            // this.$refs.newsContent.scrollTop = this.$refs.newsContent.scrollHeight;
        }
    }
};
</script>
<style lang="scss" scoped>
.home{
    margin-top: -64px;
    .banner{
        position: relative;
        overflow: hidden;
        padding-top: 64px;
        .row{
            min-height: calc(100vh - 64px);
        }
        .v-card__title{
            font-weight: 800;
            font-size: 32px;
        }
        .circle{
            position: absolute;
            content: '';
            border-radius: 50%;
            background-color: rgba(233, 210, 8, 0.3)
        }
        .circle.one{
            width: 50vw;
            height: 50vw;
            top: 100%;
            left: 0;
            transform: translate(-50%, -50%);
        }
        .circle.two{
            width: 20vw;
            height: 20vw;
            top: 20vw;
            right: 50px;
            transform: translateY(-60%);
        }
    }
    .adv-section{
        // height: 500px;
        .v-card{
            width: 300px;
            margin: auto;
            .v-card__title{
                position: absolute;
                top: 50%;
                left: 10px;
                width: 50px;
                height: 50px;
                background: #fff;
                padding: 10px;
                box-shadow: 0 1px 3px 2px #ccc;
                border-radius: 50%;
                transform: translateY(-50%);
                animation: pulse 1s linear 0.2s infinite;
            }
            .v-card__text{
                padding-left: 70px;
            }
        }
    }
    .process-img{
        padding: 100px 0;
        // transform: rotateY(90deg);
        // transition-duration: 2s;
    }
    .news-redesign{
        height: 75px;
        width: 100%;
        position: absolute;
        left: 50%;
        pointer-events: none;
        transform: translate(-50%, 64px);
        z-index: 99999999;
        text-align: center;
        overflow: auto;
        background: #f5f5f5;
        border: solid #ccc;
        border-width: 5px 0;
        z-index: 1;
        &::-webkit-scrollbar{
            display: none;
        }
    }
}
// .new-container .v-card__text{
//     width: 100%;
//     overflow: auto;
//     transform: translateY(0);
// }
.new--text{
    color: red;
}
.startScroll {
    animation: scrollAnimation var(--scroll-time-2) linear infinite;
}
@keyframes scrollAnimation {
    to{
        transform: translateY(-100%);
    }
}
</style>